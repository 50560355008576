import React from "react"
import { graphql } from "gatsby"
import DesignPage from "./index.en"

export default ({ data }) => <DesignPage data={data} />
  
export const query = graphql`
  query allDesignQuery {
    projects: allDesign(
      filter: {
        lang: {eq: "en"}, 
        status: {ne: unpublished}
      },
      sort: {fields: date, order: DESC},
      limit: 50
    ) {
      nodes {
        ...designProperties
      }
    }
  }
`

